<template>
  <div class="zoey">
    <CommonHeader @openMenu="$emit('openMenu')"></CommonHeader>
    <slot />
  </div>
  
</template>
<script setup>
</script>
<style lang="scss" scoped>
.zoey{
  background: $primary-color;
  border-radius: 5px;
  color: $zoey-black;
}

</style>
